import Vue from "vue";
import smartFormHeaderMixin from "@/mixins/smartFormHeaderMixin";
import "@/views/templates/hallways/css/hallways.scss";
import LangIcon from "@/components/LangIcon.vue";
import CommonHeader from "@/components/commons/CommonHeader.vue";
import CommonModal from "@/components/commons/CommonModal.vue";
import SearchBar from "@/components/commons/SearchBar.vue";
import SmartformFooter from "@/components/SmartformFooter.vue";
import CommonIcon from "@/components/commons/CommonIcon.vue";
import LeftPanel from "@/views/templates/hallways/components/LeftPanel.vue";
import WebRestService from "@/services/WebRestService";
import CommonMapTree from "@/components/commons/CommonMapTree.vue";
import { FormFieldTypeCodeEnum } from "@/enums";
import headerMixin from "@/mixins/headerMixin";
const webRestService = WebRestService.Instance;
export default Vue.extend({
    mixins: [
        smartFormHeaderMixin,
        headerMixin
    ],
    components: {
        CommonMapTree,
        LeftPanel,
        SmartformFooter,
        CommonHeader,
        CommonModal,
        SearchBar,
        LangIcon,
        CommonIcon
    },
    data() {
        return {
            title: "",
            subTitle: "",
            language: "",
            searchModel: "",
            rootTag: { icon: 'fas fa-tag', color: '#000000' },
            languageTagMap: {},
            map: [],
            mapFiltered: [],
            leftSideHtml: [{
                    type: 'HTML_HEADER',
                    value: {
                        text: '<h1>{{ localizationService.SiteMapLoc("site_map") }}</h1>' +
                            ' <p>{{ localizationService.SiteMapLoc("site_map_description") }} <br> ' +
                            '{{ localizationService.SiteMapLoc("smartforms") }}</p>\n' +
                            '<p>{{ localizationService.SiteMapLoc("sezione_non_trovata") }}&nbsp;,<br>' +
                            '<span style="text-decoration: underline;">{{ localizationService.SiteMapLoc("contattare_servizio") }}</span></p>'
                    },
                    title: 'Mappa del sito',
                }]
        };
    },
    mounted() {
        const lang = Vue.$cookies.get("smartform-language-" + this.customerCode);
        webRestService.getSiteMap(this.customerCode).then((entry) => {
            if (lang) {
                const language = entry.children.find((child) => child.params.find(param => param.params_type === 'FORM_LANG' && param.lang_code === lang));
                if (language) {
                    webRestService.getSiteMap(this.customerCode, language.code_tag).then((map) => {
                        this.map = [map];
                        this.mapFiltered = this.map;
                        this.setFooter(map);
                    });
                }
            }
            else {
                this.map = [entry];
                this.mapFiltered = this.map;
                this.setFooter(entry);
            }
        });
    },
    computed: {
        customerCode() {
            return this.$route.params.customerCode;
        },
        codeTag() {
            return this.$route.params.codeTag;
        }
    },
    methods: {
        setFooter(response) {
            const terms = response.form_fields.find(ff => ff.type === FormFieldTypeCodeEnum.TERMS_CONDITIONS);
            const footer = response.form_fields.find(ff => ff.type === FormFieldTypeCodeEnum.FOOTER);
            if (response.form_fields && response.form_fields.length && (!!footer || !!terms)) {
                this.$store.dispatch('footer/setFooterData', {
                    termsConditions: terms,
                    footerElems: footer
                });
            }
        },
        inputSearch(model) {
            this.mapFiltered = this.map.filter(elem => elem.description.toLowerCase().includes(this.searchModel.toLowerCase()));
        },
        goToElem(route) {
            this.$router.push(route);
        }
    }
});
